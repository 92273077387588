import { useContext, useState, useEffect, createContext, ReactNode } from "react";
import { auth, db } from "../Firebase";
import { User, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updatePassword as updateUserPassword, updateEmail as updateUserEmail, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import Spinner from "../components/spinner/Spinner";
import { setDoc, doc, DocumentData, updateDoc, onSnapshot } from "firebase/firestore";

type Props = {
  children: ReactNode;
}

const AuthContext = createContext({
  currentUser: {} as User | null,
  currentUserDoc: {} as DocumentData | null,
  signup: (email: string, password: string, username: string) => { },
  login: (email: string, password: string) => { },
  logout: () => { },
  resetPassword: (email: string) => { },
  updateEmail: (email: string) => { },
  updatePassword: (password: string) => { },
  reauthenticate: (oldPassword: string) => { },
});

export const useAuth = () => {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: Props) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentUserDoc, setCurrentUserDoc] = useState<DocumentData | null>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [userDocLoading, setUserDocLoading] = useState(true);

  const signup = async (email: string, password: string, username: string) => {
    const promise = await createUserWithEmailAndPassword(auth, email, password);

    await setDoc(doc(db, "users", promise.user.uid), {
      approved: false,
      username: username,
      dateJoined: new Date(),
      dateLastLogin: new Date(),
    });

    return promise;
  }

  const login = async (email: string, password: string) => {
    const promise = await signInWithEmailAndPassword(auth, email, password);

    await updateDoc(doc(db, 'users', promise.user.uid), {
      dateLastLogin: new Date(),
    });

    return promise;
  }

  const logout = () => {
    return signOut(auth);
  }

  const resetPassword = (email: string) => {
    return sendPasswordResetEmail(auth, email);
  }

  const updateEmail = async (email: string) => {
    return updateUserEmail(currentUser!, email);
  }

  const updatePassword = async (password: string) => {
    return updateUserPassword(currentUser!, password);
  }

  const reauthenticate = async (currentPassword: string) => {
    const credential = EmailAuthProvider.credential(
      currentUser!.email!,
      currentPassword
    )

    return await reauthenticateWithCredential(currentUser!, credential);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUserLoading(true);
      setCurrentUser(user);
      setUserLoading(false);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setUserDocLoading(true);

    if (currentUser?.uid! !== undefined) {
      const unsubscribe = onSnapshot(doc(db, "users", currentUser?.uid!), (doc) => {
        setCurrentUserDoc({
          id: doc.id,
          ...doc.data(),
        })

        setUserDocLoading(false);
      });

      return unsubscribe
    }
  }, [currentUser])

  const value = {
    currentUser,
    currentUserDoc,
    signup,
    login,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    reauthenticate
  }

  return (
    <AuthContext.Provider value={value}>
      {userLoading || (userDocLoading && currentUser != null)
        ? <Spinner />
        : children
      }
    </AuthContext.Provider>
  )
}

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

export default function Goback() {
    const navigate = useNavigate();


    return (
        <Button variant="secondary" onClick={() => navigate(-1)}>Go back</Button>
    )
}

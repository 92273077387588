import { FormEvent, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { FirebaseError } from "@firebase/util";
import { PageTitle } from "../../utils/PageTitle";
import Spinner from "../../components/spinner/Spinner";
import { getCountFromServer, query, collection, where } from "firebase/firestore";
import { db } from "../../Firebase";

export default function Signup() {
  PageTitle("Signup");
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const username = usernameRef.current!.value.toLowerCase();

    if (passwordRef.current!.value !== passwordConfirmRef.current!.value) {
      return setError("Passwords do not match");
    }

    const countSnapshot = await getCountFromServer(query(collection(db, 'users'), where('username', '==', username)))
    if (countSnapshot.data().count > 0) {
      return setError('Username is already in use')
    }

    if (username.length > 20) return setError('Username is too long (max 20 characters)')

    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current!.value, passwordRef.current!.value, username);
      navigate("/");
    } catch (ex) {
      // console.log(ex);
      setError("Failed to create an account");
      if (ex instanceof FirebaseError) setError(ex.code);
    }

    setLoading(false)
  }

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Spinner isLoading={loading} />
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Sign Up</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email" className="form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group id="username" className="form-group">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" maxLength={20} ref={usernameRef} required />
              </Form.Group>
              <Form.Group id="password" className="form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Form.Group id="password-confirm" className="form-group">
                <Form.Label>Password confirmation</Form.Label>
                <Form.Control type="password" ref={passwordConfirmRef} required />
              </Form.Group>
              <Button disabled={loading} className="w-100" type="submit">Sign Up</Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          Already have an account? <Link to="/login">Log In</Link>
        </div>
      </div>
    </Container>
  )
}

import { Button, Container, Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext";
import { hasPermission, roles } from "../utils/Roles";

function Header() {
    const { currentUserDoc, logout } = useAuth();

    return (
        <Navbar expand="sm" className="bg-body-tertiary mb-4" style={{ zIndex: 2 }}>
            <Container>
                <Link to="/" className="navbar-brand">LEADERBOARD</Link>
                <Navbar.Toggle />

                <Navbar.Collapse>
                    {hasPermission(roles.ADMIN.permissionLevel, currentUserDoc!.role) && (
                        <Nav><Link to="/users" className="nav-link">User management</Link></Nav>
                    )}
                </Navbar.Collapse>

                <Navbar.Collapse className="justify-content-end">
                    <Nav><Link to="/update-profile" className="nav-link">{currentUserDoc!.username}</Link></Nav>
                    <Nav><Button variant="link" className="nav-link text-start" onClick={() => logout()}>Log out</Button></Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { getPermissionLevel } from "../../utils/Roles";

type Props = {
    permissionLevel: number
}

export default function PermissionRoute({ permissionLevel }: Props) {
    const { currentUserDoc } = useAuth();

    if (getPermissionLevel(currentUserDoc!.role) < permissionLevel) return <Navigate to='/' />;

    return <Outlet />;
}
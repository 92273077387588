// https://loading.io/css/
import "./Spinner.scss"

type Props = {
    isLoading?: boolean
}

export default function Spinner({ isLoading }: Props) {
    if (isLoading === undefined || isLoading === true) {
        return <div className="spinner-background"><div className="spinner-wrapper"><div></div><div></div><div></div><div></div></div></div>
    } else {
        return <div className="spinner-background" style={{ display: 'none' }}><div className="spinner"><div></div><div></div><div></div><div></div></div></div>
    }
}
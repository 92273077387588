export const roles = {
    ADMIN: {
        name: "admin",
        permissionLevel: 3,
    },
    EDITOR: {
        name: "editor",
        permissionLevel: 2,
    },
    USER: {
        name: "user",
        permissionLevel: 1,
    },
}

export const getPermissionLevel = (roleName?: string) => {
    if (roleName === undefined || roleName === null || roleName === "") return 1

    const result = Object.values(roles).find(role => role.name === roleName.toLowerCase())

    if (result) {
        return result.permissionLevel;
    } else {
        return 1
    }
}

export const hasPermission = (requiredPermissionLevel: number, roleName?: string) => {
    const permissionLevel = getPermissionLevel(roleName);
    return permissionLevel >= requiredPermissionLevel;
}
import { Button, Card, Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { PageTitle } from "../../utils/PageTitle";

export default function NotFound() {
    PageTitle("404");
    const navigate = useNavigate();

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
            <div className="w-100" style={{ maxWidth: "400px" }}>
                <Card>
                    <Card.Body>
                        <h1 className="text-center mb-4 fw-bold">404</h1>
                        <p className="text-center mb-4">The page you're looking for does not exist.</p>
                        <Button className="w-100" onClick={() => navigate(-1)}>Go back</Button>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    )
}
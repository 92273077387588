import { Timestamp } from "firebase/firestore";
import moment from "moment";

const defaultFormat = 'YYYY/MM/DD';

export const timestampToDate = (timestamp: Timestamp, format?: string) => {
    if (typeof (timestamp) === "object") {
        let date = timestamp.toDate();
        if (format) {
            return moment(date).format(format);
        } else {
            return moment(date).format(defaultFormat);
        }
    }
};

export const timestampToDateObject = (timestamp: Timestamp) => {
    return timestamp.toDate();
};

export const createNewDate = (format: string) => {
    if (format) {
        return moment(new Date()).format(format)
    } else {
        return moment(new Date()).format(defaultFormat)
    }
}

export const dateObjectToDate = (date: Date, format?: string) => {
    if (format) {
        return moment(date).format(format)
    } else {
        return moment(date).format(defaultFormat)
    }
}
import { Button, Card, Container } from "react-bootstrap"
import { PageTitle } from "../../utils/PageTitle";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function NotApproved() {
    PageTitle("403");
    const { currentUserDoc, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUserDoc!.approved === true) navigate('/', { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserDoc]);

    return (
        <Container className="d-flex align-items-center justify-content-center">
            <div className="w-100" style={{ maxWidth: "400px" }}>
                <Card>
                    <Card.Body>
                        <h1 className="text-center mb-4 fw-bold">403</h1>
                        <p className="text-center mb-4">Your account has not been approved yet.</p>
                        <Button className="w-100" onClick={() => logout()}>Log out</Button>
                    </Card.Body>
                </Card>
            </div>
        </Container>
    )
}
import { FormEvent, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { FirebaseError } from "@firebase/util";
import { PageTitle } from "../../utils/PageTitle";
import Spinner from "../../components/spinner/Spinner";

export default function UpdateProfile() {
  PageTitle("Update Profile");
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordOldRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);
  const { currentUser, updatePassword, updateEmail, reauthenticate } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (passwordRef.current!.value !== passwordConfirmRef.current!.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    try {
      await reauthenticate(passwordOldRef.current!.value);
    } catch (ex) {
      // console.log(ex);
      setError("Failed to update account");
      if (ex instanceof FirebaseError) setError(ex.code);
      setLoading(false);

      return;
    }

    if (emailRef.current!.value !== currentUser!.email) {
      promises.push(updateEmail(emailRef.current!.value));
    }
    if (passwordRef.current!.value) {
      promises.push(updatePassword(passwordRef.current!.value));
    }

    Promise.all(promises)
      .then(() => {
        navigate("/");
      })
      .catch((ex) => {
        // console.log(ex);
        setError("Failed to update account");
        if (ex instanceof FirebaseError) setError(ex.code);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <Container className="d-flex align-items-center justify-content-center">
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Spinner isLoading={loading} />
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Update Profile</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email" className="form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  ref={emailRef}
                  required
                  defaultValue={currentUser!.email!}
                />
              </Form.Group>
              <Form.Group id="password" className="form-group">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordRef}
                  placeholder="Leave blank to keep the same"
                />
              </Form.Group>
              <Form.Group id="password-confirm" className="form-group">
                <Form.Label>Password Confirmation</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordConfirmRef}
                  placeholder="Leave blank to keep the same"
                />
              </Form.Group>
              <Form.Group id="password-old" className="form-group">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  ref={passwordOldRef}
                  required
                />
              </Form.Group>
              <Button disabled={loading} className="w-100" type="submit">Update</Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          <Button variant="link" onClick={() => navigate(-1)}>Cancel</Button>
        </div>
      </div>
    </Container>
  )
}

import { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom"
import Modal from 'react-bootstrap/Modal';
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where, documentId } from "firebase/firestore";
import { db } from "../../Firebase";
import { User } from "../../types/User";
import Spinner from "../../components/spinner/Spinner";
import { useAuth } from "../../contexts/AuthContext";
import { LeaderboardEntry } from "../../types/LeaderboardEntry";
import { dateObjectToDate, timestampToDateObject } from "../../utils/DateConversions";
import Empty from "../../components/Empty";
import { PageTitle } from "../../utils/PageTitle";
import Goback from "../../components/Goback";
import Select from "react-select";
import { hasPermission, roles } from "../../utils/Roles";
import { FaTrashAlt, FaEdit } from 'react-icons/fa'
import { LeaderboardRank } from "../../types/LeaderboardRank";

export default function LeaderboardDetail() {
    const { leaderboardId } = useParams();

    const [users, setUsers] = useState<User[]>([]);
    const [leaderboardEntries, setLeaderboardEntries] = useState<LeaderboardEntry[]>([]);
    const [leaderboardRankings, setLeaderboardRankings] = useState<LeaderboardRank[]>([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState<null | string>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const userPlayedAgainstRef = useRef<string | null | undefined>(null);
    const currentUserWinRef = useRef<HTMLInputElement>(null);
    const { currentUser, currentUserDoc } = useAuth();
    const navigate = useNavigate();

    // Database calls
    const getUsers = async () => {
        const querySnapshot = await getDocs(query(collection(db, 'users'), where(documentId(), '!=', currentUser!.uid), where('approved', '==', true)));
        const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));

        setUsers(data);
    }

    const getLeaderboardDetails = async () => {
        const leaderboardDoc = await getDoc(doc(db, 'leaderboards', leaderboardId!));

        if (leaderboardDoc.data() === undefined) navigate('/not-found', { replace: true });
        PageTitle(leaderboardDoc.data()?.name);
    }

    const getLeaderboardRanking = async () => {
        const participants = new Set<string>();
        leaderboardEntries.forEach(entry => {
            participants.add(entry.winner!);
            participants.add(entry.loser!);
        });


        let leaderboardRanks: LeaderboardRank[] = [];
        participants.forEach(participant => leaderboardRanks.push({
            id: participant,
            username: getUsernameById(participant),
            wins: leaderboardEntries.filter(entry => entry.winner === participant).length,
            losses: leaderboardEntries.filter(entry => entry.loser === participant).length,
            lastWinDate: leaderboardEntries.filter(entry => entry.winner === participant)[0]?.datePlayed!,
        }));

        leaderboardRanks.forEach((leaderboardRank) => {
            leaderboardRank.gamesPlayed = leaderboardRank.wins + leaderboardRank.losses;
            leaderboardRank.winPercentage = (leaderboardRank.wins / leaderboardRank.gamesPlayed) || 0; // Avoid division by zero
        });

        // Sort the array based on win percentage, games played, and last win date
        leaderboardRanks.sort((a, b) => {
            // Sort by win percentage (descending order)
            if (a.winPercentage !== b.winPercentage) {
                return b.winPercentage! - a.winPercentage!;
            }

            // If win percentage is the same, sort by games played (ascending order)
            if (a.gamesPlayed !== b.gamesPlayed) {
                return a.gamesPlayed! - b.gamesPlayed!;
            }

            // If games played are the same, sort by last win date (ascending order)
            return a.lastWinDate!.getTime() - b.lastWinDate!.getTime();
        });

        setLeaderboardRankings(leaderboardRanks);
        setLoading(false);
    }


    // Execute database calls
    useEffect(() => {
        const executeDatabaseCalls = async () => {
            await getUsers();
            await getLeaderboardDetails();
        }

        executeDatabaseCalls();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get leaderboard entries via snapshot
    useEffect(() => {
        const q = query(collection(db, 'leaderboardEntries'), where('leaderboardId', '==', leaderboardId));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setLoading(true);

            const data: LeaderboardEntry[] = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                datePlayed: timestampToDateObject(doc.data().datePlayed),
            }));

            data.sort((a: LeaderboardEntry, b: LeaderboardEntry) => b.datePlayed!.getTime() - a.datePlayed!.getTime());
            setLeaderboardEntries(data);
        });

        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        getLeaderboardRanking();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaderboardEntries]);


    const updateLastUpdated = async () => {
        await updateDoc(doc(db, 'leaderboards', leaderboardId!), {
            dateLastUpdated: new Date(),
        });
    }

    const addEntry = async (winnerId: string, loserId: string) => {
        await setDoc(doc(collection(db, "leaderboardEntries")), {
            datePlayed: new Date(),
            leaderboardId: leaderboardId,
            winner: winnerId,
            loser: loserId,
        });

        updateLastUpdated();
    }

    const deleteEntry = async () => {
        if (showDeleteModal != null) {
            await deleteDoc(doc(db, "leaderboardEntries", showDeleteModal));

            setShowDeleteModal(null);
            updateLastUpdated();
        }
    }

    const getUsernameById = (id: string) => {
        if (id === currentUser?.uid) return 'Me';
        return users.find(user => id === user.id) == null ? 'Disabled user' : users.find(user => id === user.id)!.username!;
    }

    // modals
    const AddModal = () => {
        return (<>
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Add entry</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form>
                        <Form.Group className="form-group">
                            <Select
                                options={users.map(user => ({ value: user.id, label: user.username }))}
                                onChange={(element) => userPlayedAgainstRef.current = element?.value}
                                className="react-select-container"
                                classNamePrefix="react-select"
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Check
                                type="switch"
                                id="win"
                                label="Win?"
                                ref={currentUserWinRef}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => handleAddModalSubmit()}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
        )
    }

    const handleAddModalSubmit = () => {
        let passed = true;
        setError('');

        if (userPlayedAgainstRef.current === null || userPlayedAgainstRef.current === undefined) {
            passed = false;
            setError("Select a player");
        }

        if (passed) {
            if (currentUserWinRef.current!.checked) {
                addEntry(currentUser!.uid, userPlayedAgainstRef.current!)
            } else {
                addEntry(userPlayedAgainstRef.current!, currentUser!.uid);
            }

            userPlayedAgainstRef.current = null;
            setShowAddModal(false);
        }
    }

    const EditModal = () => {
        return (<>
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Edit entry</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    Not yet implemented
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => console.log("Not yet implemented")}>Edit</Button>
                </Modal.Footer>
            </Modal>
        </>
        )
    }

    const DeleteModal = () => {
        return (<>
            <Modal show={showDeleteModal != null} onHide={() => setShowDeleteModal(null)} centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Delete entry</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    Are you sure you want to delete this entry?
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={() => deleteEntry()}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
        )
    }

    if (loading) return <Spinner />;

    return (
        <>
            <div className="mb-4 d-flex justify-content-between">
                <Goback />
                <Button onClick={() => setShowAddModal(true)}>Add</Button>
            </div>

            {leaderboardEntries.length === 0
                ? <Empty />
                : <>
                    <Table className="mb-5" striped bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Wins</th>
                                <th>Losses</th>
                                <th>Win %</th>
                            </tr>
                        </thead>

                        <tbody>
                            {leaderboardRankings.map((ranking, i) =>
                                <tr key={'rank-' + ranking.id} className={'rank-' + (i + 1)}>
                                    <td>{i + 1}</td>
                                    <td>{ranking.username}</td>
                                    <td>{ranking.wins}</td>
                                    <td>{ranking.losses}</td>
                                    <td>{Math.round(ranking.winPercentage! * 100)}%</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>


                    <h1>Entries</h1>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Winner</th>
                                <th>Loser</th>
                                {hasPermission(roles.EDITOR.permissionLevel, currentUserDoc!.role) &&
                                    <th></th>
                                }
                            </tr>
                        </thead>

                        <tbody>
                            {leaderboardEntries.map((entry, i) =>
                                <tr key={'entry-' + entry.id}>
                                    <td>{leaderboardEntries.length - i}</td>
                                    <td>{dateObjectToDate(entry.datePlayed!, 'YYYY/MM/DD, HH:mm')}</td>
                                    <td>{getUsernameById(entry.winner!)}</td>
                                    <td>{getUsernameById(entry.loser!)}</td>
                                    {hasPermission(roles.EDITOR.permissionLevel, currentUserDoc!.role) &&
                                        <td>
                                            <FaEdit className="clickableIcon" onClick={() => setShowEditModal(true)} />
                                            <FaTrashAlt className="clickableIcon" onClick={() => setShowDeleteModal(entry.id!)} />
                                        </td>
                                    }
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </>
            }

            <AddModal />
            <EditModal />
            <DeleteModal />
        </>
    )
}

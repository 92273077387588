import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Header from "../Navbar";
import { Container } from "react-bootstrap";
import { roles } from "../../utils/Roles";


export default function AuthenticatedRoute() {
  const { currentUser, currentUserDoc } = useAuth();
  const location = useLocation();

  if (currentUser == null) return <Navigate to='/login' />;
  if (currentUserDoc?.role !== roles.ADMIN.name && (currentUserDoc?.approved === false && location.pathname !== '/not-approved')) return <Navigate to='/not-approved' />;

  return (
    <>
      <Header />
      <Container>
        <Outlet />
      </Container>
    </>
  )
}
